export const WRONG_CHAIN_MESSAGE = process.env.REACT_APP_WRONG_CHAIN_MESSAGE;
export const SUPPORTED_CHAIN_IDS = process.env.REACT_APP_SUPPORTED_CHAIN_IDS;
export const SUPPORTED_CHAIN_NAMES =
  process.env.REACT_APP_SUPPORTED_CHAIN_NAMES;

//get suppoertedChainNames
export const getSupportedChainNames = () => {
  let chains = {};
  SUPPORTED_CHAIN_IDS.split(",").map((id) => {
    chains[parseInt(id.trim())] =
      SUPPORTED_CHAIN_NAMES.split(",")[
        SUPPORTED_CHAIN_IDS.split(",").indexOf(id)
      ].trim();
  });
  return chains;
};

//ETHEREUM
export const GRAPHAPIURL_MASTER_ETHEREUM =
  process.env.REACT_APP_GRAPHAPIURL_LEND_ETHEREUM;
export const CONTRACT_ADDRESS_MASTER_ETHEREUM =
  process.env.REACT_APP_MASTER_CONTRACT_ADDRESS_ETHEREUM;
export const CONTRACT_ADDRESS_ORACLE_ETHEREUM =
  process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS_ETHEREUM;
export const CONTRACT_ADDRESS_LEND_ETHEREUM =
  process.env.REACT_APP_LEND_CONTRACT_ADDRESS_ETHEREUM;
export const ETHEREUM_CHAIN_ID = process.env.REACT_APP_ETHEREUM_CHAIN_ID;
export const STABLE_COIN_SYMBOLS_ETHEREUM =
  process.env.REACT_APP_STABLE_COIN_SYMBOLS_ETHEREUM;
export const STABLE_COIN_ADDRESSES_ETHEREUM =
  process.env.REACT_APP_STABLE_COIN_ADDRESSES_ETHEREUM;
export const STABLE_COIN_DECIMALS_ETHEREUM =
  process.env.REACT_APP_STABLE_COIN_DECIMALS_ETHEREUM;
export const WRAPPED_URL_ETHEREUM = process.env.REACT_APP_WRAPPED_URL_ETHEREUM;

//BSC
export const GRAPHAPIURL_MASTER_BSC =
  process.env.REACT_APP_GRAPHAPIURL_LEND_BSC;
export const CONTRACT_ADDRESS_MASTER_BSC =
  process.env.REACT_APP_MASTER_CONTRACT_ADDRESS_BSC;
export const CONTRACT_ADDRESS_ORACLE_BSC =
  process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS_BSC;
export const CONTRACT_ADDRESS_LEND_BSC =
  process.env.REACT_APP_LEND_CONTRACT_ADDRESS_BSC;
export const BSC_CHAIN_ID = process.env.REACT_APP_BSC_CHAIN_ID;
export const STABLE_COIN_SYMBOLS_BSC =
  process.env.REACT_APP_STABLE_COIN_SYMBOLS_BSC;
export const STABLE_COIN_ADDRESSES_BSC =
  process.env.REACT_APP_STABLE_COIN_ADDRESSES_BSC;
export const STABLE_COIN_DECIMALS_BSC =
  process.env.REACT_APP_STABLE_COIN_DECIMALS_BSC;
export const WRAPPED_URL_BSC = process.env.REACT_APP_WRAPPED_URL_BSC;

//POLYGON
export const GRAPHAPIURL_MASTER_POLYGON =
  process.env.REACT_APP_GRAPHAPIURL_LEND_POLYGON;
export const CONTRACT_ADDRESS_MASTER_POLYGON =
  process.env.REACT_APP_MASTER_CONTRACT_ADDRESS_POLYGON;
export const CONTRACT_ADDRESS_ORACLE_POLYGON =
  process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS_POLYGON;
export const CONTRACT_ADDRESS_LEND_POLYGON =
  process.env.REACT_APP_LEND_CONTRACT_ADDRESS_POLYGON;
export const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_CHAIN_ID;
export const STABLE_COIN_SYMBOLS_POLYGON =
  process.env.REACT_APP_STABLE_COIN_SYMBOLS_POLYGON;
export const STABLE_COIN_ADDRESSES_POLYGON =
  process.env.REACT_APP_STABLE_COIN_ADDRESSES_POLYGON;
export const STABLE_COIN_DECIMALS_POLYGON =
  process.env.REACT_APP_STABLE_COIN_DECIMALS_POLYGON;
export const WRAPPED_URL_POLYGON = process.env.REACT_APP_WRAPPED_URL_POLYGON;

//AVALANCHE
export const GRAPHAPIURL_MASTER_AVALANCHE =
  process.env.REACT_APP_GRAPHAPIURL_LEND_AVALANCHE;
export const CONTRACT_ADDRESS_MASTER_AVALANCHE =
  process.env.REACT_APP_MASTER_CONTRACT_ADDRESS_AVALANCHE;
export const CONTRACT_ADDRESS_ORACLE_AVALANCHE =
  process.env.REACT_APP_ORACLE_CONTRACT_ADDRESS_AVALANCHE;
export const CONTRACT_ADDRESS_LEND_AVALANCHE =
  process.env.REACT_APP_LEND_CONTRACT_ADDRESS_AVALANCHE;
export const AVALANCHE_CHAIN_ID = process.env.REACT_APP_AVALANCHE_CHAIN_ID;
export const STABLE_COIN_SYMBOLS_AVALANCHE =
  process.env.REACT_APP_STABLE_COIN_SYMBOLS_AVALANCHE;
export const STABLE_COIN_ADDRESSES_AVALANCHE =
  process.env.REACT_APP_STABLE_COIN_ADDRESSES_AVALANCHE;
export const STABLE_COIN_DECIMALS_AVALANCHE =
  process.env.REACT_APP_STABLE_COIN_DECIMALS_AVALANCHE;
export const WRAPPED_URL_AVALANCHE =
  process.env.REACT_APP_WRAPPED_URL_AVALANCHE;
